import { Merger, NoMerger } from "./common"
import Legacy, { Row as LegacyRow } from "./legacy"
import Default, { Row as DefaultRow } from "./default"

export class NoMergeLegacy extends Legacy {
  readonly merger: Merger<LegacyRow> = new NoMerger()
}

export class NoMerge extends Default {
  readonly merger: Merger<DefaultRow> = new NoMerger()
}
