import { Client } from "@today/api/taker"
import { getShippingTypes, isLegacyClient } from ".."
import { Binder } from "./common"
import DasanGlobal from "./dasanglobal"
import DBLKorea from "./dblkorea"
import Default from "./default"
import DefaultLmToLm from "./default-lm-to-lm"
import FlexibleBinder from "./flexible"
import Fmj from "./fmj"
import Legacy, { LegacyMergeWithReceiverAddress } from "./legacy"
import LotteImall from "./lotteimall"
import TruckCaller from "./truck-caller"
import { NoMerge, NoMergeLegacy } from "./no-merge"

const binders: { [k: string]: () => Binder<any> } = {
  legacy: () => new Legacy(),
  default: () => new Default(),
  defaultLmToLm: () => new DefaultLmToLm(),
  truckCaller: () => new TruckCaller(),
  "120670196598": () => new Fmj(),
  "560868260125": () => new Fmj(),
  "100928880033": () => new Fmj(),
  "510777721850": () => new Fmj(),
  // 수플린
  "155734090441": () => new NoMergeLegacy(),
  "510546414274": () => new NoMergeLegacy(),
  "108472190524": () => new LotteImall(),
  "535303546850": () => new LotteImall(),
  "165592320332": () => new NoMergeLegacy(),
  "582049421892": () => new NoMergeLegacy(),
  "567022329412": () => new DBLKorea(),
  "145529237532": () => new DBLKorea(),
  // 바다대로
  "147131918542": () => new NoMergeLegacy(),
  "513812519534": () => new NoMergeLegacy(),
  "188723527266": () => new DasanGlobal(),
  "577922142454": () => new DasanGlobal(),
  // 온브릭스
  "196824672422": () => new NoMergeLegacy(),
  "569022575362": () => new NoMergeLegacy(),
  // 셀럽메디스파
  "138655333910": () => new DefaultLmToLm(),
  "572917994863": () => new DefaultLmToLm(),
  // 꾸까
  "195827224672": () => new NoMergeLegacy(),
  "567251159926": () => new NoMergeLegacy(),
  // 투데이 (테스트용)
  //"598469867012": () => new FlexibleBinder(),
  // 디오네코리아 (테스트)
  "587152971379": () => new FlexibleBinder(),
  // 글로썸 (테스트)
  "563559060502": () => new FlexibleBinder(),
  // 신야치킨
  "121077199206": () => new FlexibleBinder(),
  "591049937783": () => new FlexibleBinder(),
  // 신포보리당 호두과자
  "106244626141": () => new FlexibleBinder(),
  "584982375721": () => new FlexibleBinder(),
  // 미소차이나
  "135920610559": () => new FlexibleBinder(),
  "505966240972": () => new FlexibleBinder(),
  // 우진
  "155570418612": () => new LegacyMergeWithReceiverAddress(),
  "544503821229": () => new LegacyMergeWithReceiverAddress(),
  // 파미
  "181543054294": () => new NoMerge(),
  "502297348475": () => new NoMerge(),
}

export default function getBinder(client?: Client, clientRoles?: string[]) {
  if (!client) return binders["default"]()
  if (clientRoles?.includes("order:truck-caller"))
    return binders["truckCaller"]()
  const shippingTypes = getShippingTypes(client)
  return (
    binders[client.clientId] ??
    binders[
      isLegacyClient(client)
        ? "legacy"
        : shippingTypes.includes("LM_TO_LM") &&
          (shippingTypes.length === 1 ||
            (shippingTypes.length === 2 &&
              client.conditions.some(
                (condition) =>
                  condition.deliveryClasses.includes("FORWARDING_24") &&
                  condition.shippingType === "LM_TO_STATION"
              )))
        ? "defaultLmToLm"
        : "default"
    ]
  )()
}
