import axios from "axios"
import applyCaseMiddleware from "axios-case-converter"
import {
  BulkCreateOrderResponse,
  CleanAddressResponse,
  ClientCondition,
  CreateClientConditionRequest,
  CreateClientRequest,
  CreateShippingPlaceRequest,
  ListRegionsResponse,
  ListReturningOrdersResponse,
  ListTemporaryOrdersResponse,
  Order,
  PartialUpdateClientRequest,
  PatchOrderRequest,
  RawListRegionsResponse,
  RetryTemporaryOrdersResponse,
  TaxInvoice,
  Billing,
  BulkCreateOrderRequest,
  Client,
  CreateBillingRequest,
  CreateOrderRequest,
  ListClientsResponse,
  ListOrdersResponse,
  ListShippingPlacesResponse,
  RetrieveRegionResponse,
  ShippingPlace,
  TemporaryOrder,
  PrepaidPoints,
  PrepaidPointsTransaction,
  PrepaidPointsTransactionType,
  ListBillingsRequest,
  ListBillingOrdersRequest,
  ListClientBillingsRequest,
  ClientBillingSummary,
  ListClientBillingOrdersRequest,
  ListClientOrderSummariesRequest,
  Account,
} from "."
import { DeliveryClass, TodayAPIClient } from "../common"
import {
  encodeFilter,
  PaginationRequest,
  PaginationResponse,
  withPagination,
} from "../utils"

export const LIST_PAGE_SIZE = "100"

export type OrderRetrievalType =
  | "order_id"
  | "invoice_number"
  | "client_shipping_id"
  | "forwarding_invoice_number"

export class Taker extends TodayAPIClient {
  constructor(baseUrl: string, token?: string) {
    super(
      applyCaseMiddleware(
        axios.create({
          baseURL: baseUrl,
          headers: {
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
          },
        })
      )
    )
  }

  async listClients(
    queryParams: Record<string, string>,
    eTag?: string
  ): Promise<{
    data: ListClientsResponse
    headers: Record<string, string>
    status: number
  }> {
    const paramsString = new URLSearchParams(queryParams)
    const { data, headers, status } = await this.client.get(
      `/client/v1/clients?${paramsString}`,
      eTag
        ? {
            headers: {
              ["If-None-Match"]: eTag,
            },
          }
        : {}
    )
    return { data, headers, status }
  }

  async createClient(request: CreateClientRequest): Promise<Client> {
    const { data } = await this.client.post(`/client/v1/clients`, request)
    return data as Client
  }

  async retrieveClient(clientId: string): Promise<Client> {
    const { data } = await this.client.get(`/client/v1/clients/${clientId}`)
    return data as Client
  }

  async partialUpdateClient(
    clientId: string,
    req: PartialUpdateClientRequest
  ): Promise<Client> {
    const { data } = await this.client.patch(
      `/client/v1/clients/${clientId}`,
      req
    )
    return data as Client
  }

  async listBillingClients(
    clientId: string,
    params?: PaginationRequest
  ): Promise<PaginationResponse<"clients", Client>> {
    const paramsString = withPagination(params)
    const { data } = await this.client.get(
      `/client/v1/clients/${clientId}/billing-clients?${paramsString}`
    )
    return data as PaginationResponse<"clients", Client>
  }

  async bulkCreateClientCondition(
    conditions: CreateClientConditionRequest[]
  ): Promise<{
    conditions: (ClientCondition & { index: number })[]
    failedConditions: { index: number; reason: string }[]
  }> {
    const { data } = await this.client.post(
      `/client/v1/clients/conditions/bulk-create`,
      { conditions }
    )
    return data as {
      conditions: (ClientCondition & { index: number })[]
      failedConditions: { index: number; reason: string }[]
    }
  }

  async updateClientCondition(
    clientId: string,
    conditionId: string,
    deliveryClasses?: DeliveryClass[],
    forwardingDeliveryOrganizationName?: string
  ): Promise<ClientCondition> {
    const { data } = await this.client.patch(
      `/client/v1/clients/${clientId}/conditions/${conditionId}`,
      {
        deliveryClasses,
        forwardingDeliveryOrganizationName,
      }
    )
    return data as ClientCondition
  }

  async deleteClientCondition(
    clientId: string,
    conditionId: string
  ): Promise<void> {
    await this.client.delete(
      `/client/v1/clients/${clientId}/conditions/${conditionId}`
    )
  }

  async listShippingPlacesInternal(
    pageToken = "",
    pageSize = LIST_PAGE_SIZE
  ): Promise<PaginationResponse<"shippingPlaces", ShippingPlace>> {
    const params = new URLSearchParams({
      page_token: pageToken,
      page_size: pageSize,
    })
    const { data } = await this.client.get(
      `/client/v1/shipping-places?${params}`
    )
    return data
  }

  async listShippingPlaces(
    clientId: string
  ): Promise<ListShippingPlacesResponse> {
    const { data } = await this.client.get(
      `/client/v1/clients/${clientId}/shipping-places`
    )
    return data as ListShippingPlacesResponse
  }

  async createShippingPlace(
    request: CreateShippingPlaceRequest
  ): Promise<ShippingPlace> {
    const { data } = await this.client.post(
      `/client/v1/shipping-places`,
      request
    )
    return data as ShippingPlace
  }

  async retrieveShippingPlace(shippingPlaceId: string): Promise<ShippingPlace> {
    const { data } = await this.client.get(
      `/client/v1/shipping-places/${shippingPlaceId}`
    )
    return data as ShippingPlace
  }

  async listTemporaryOrders(params: {
    fromDate?: string
    toDate?: string
    clientIds?: string[]
    isFixed?: boolean
    pageSize?: number
    pageToken?: string
  }): Promise<ListTemporaryOrdersResponse> {
    const { fromDate, toDate, clientIds, isFixed } = params
    const queryParams = new URLSearchParams()
    if (params.pageSize) {
      queryParams.append("page_size", params.pageSize.toString())
    }
    if (params.pageToken) {
      queryParams.append("page_token", params.pageToken)
    }
    const filter = [
      fromDate ? `order_date_from=${fromDate}` : null,
      toDate ? `order_date_to=${toDate}` : null,
      clientIds && clientIds.length > 0
        ? `client_id=${clientIds.join(",")}`
        : null,
      isFixed !== undefined ? `fixed=${isFixed ? "true" : "false"}` : null,
    ]
      .filter((d) => !!d)
      .join(";")
    if (filter) {
      queryParams.append("filter", filter)
    }
    const { data } = await this.client.get(
      `/order/v1/temporary-orders?${queryParams.toString()}`
    )
    return data
  }

  async getTemporaryOrder(orderId: string): Promise<TemporaryOrder> {
    const { data } = await this.client.get(
      `/order/v1/temporary-orders/${orderId}`
    )
    return data
  }

  async patchTemporaryOrder(
    orderId: string,
    payload: {
      senderAddress?: string
      senderPostalCode?: string
      receiverAddress?: string
      receiverPostalCode?: string
    }
  ): Promise<TemporaryOrder> {
    const {
      senderAddress,
      senderPostalCode,
      receiverAddress,
      receiverPostalCode,
    } = payload
    const { data } = await this.client.patch(
      `/order/v1/temporary-orders/${orderId}`,
      {
        ...(senderAddress
          ? {
              fixedSender: {
                address: senderAddress,
                postalCode: senderPostalCode ?? "",
              },
            }
          : {}),
        ...(receiverAddress
          ? {
              fixedReceiver: {
                address: receiverAddress,
                postalCode: receiverPostalCode ?? "",
              },
            }
          : {}),
      }
    )
    return data
  }

  async retryTemporaryOrders(
    orderIds: string[]
  ): Promise<RetryTemporaryOrdersResponse> {
    const { data } = await this.client.post(
      `/order/v1/temporary-orders/retry`,
      { ids: orderIds }
    )
    return data
  }

  async cleanAddress(
    address: string,
    postalCode?: string
  ): Promise<CleanAddressResponse> {
    const { data } = await this.client.post(`/order/v1/clean-address`, {
      address,
      ...(postalCode ? { postalCode } : {}),
    })
    return data
  }

  async clientCleanAddress(
    clientId: string,
    address: string
  ): Promise<CleanAddressResponse> {
    const { data } = await this.client.post(
      `/order/v1/clients/${clientId}/clean-address`,
      {
        address,
      }
    )
    return data
  }

  async listOrdersInternal(params: {
    fromDate?: string
    toDate?: string
    pageToken?: string
  }): Promise<ListOrdersResponse> {
    const { fromDate, toDate, pageToken } = params
    const queryParams = new URLSearchParams({
      page_size: LIST_PAGE_SIZE,
    })
    if (pageToken) {
      queryParams.append("page_token", pageToken)
    }
    if (fromDate || toDate) {
      queryParams.append(
        "filter",
        [
          fromDate ? `order_date_from=${fromDate}` : null,
          toDate ? `order_date_to=${toDate}` : null,
        ]
          .filter((d) => !!d)
          .join(",")
      )
    }
    const { data } = await this.client.get(
      `/order/v1/orders?${queryParams.toString()}`
    )
    return data
  }

  async listOrders(params: {
    clientId: string
    fromDate?: string
    toDate?: string
    pageToken?: string
  }): Promise<ListOrdersResponse> {
    const { clientId, fromDate, toDate, pageToken } = params
    const queryParams = new URLSearchParams({
      page_size: LIST_PAGE_SIZE,
      order: "order_time.asc",
    })
    if (pageToken) {
      queryParams.append("page_token", pageToken)
    }
    if (fromDate || toDate) {
      queryParams.append(
        "filter",
        [
          fromDate ? `order_date_from=${fromDate}` : null,
          toDate ? `order_date_to=${toDate}` : null,
        ]
          .filter((d) => !!d)
          .join(",")
      )
    }
    const { data } = await this.client.get(
      `/order/v1/clients/${clientId}/orders?${queryParams.toString()}`
    )
    return data
  }

  async listReturningOrdersInternal(
    orderId: string
  ): Promise<ListReturningOrdersResponse> {
    const { data } = await this.client.get(
      `/order/v1/orders/${orderId}/returning-orders`
    )
    return data
  }

  async createOrder(
    clientId: string,
    request: CreateOrderRequest
  ): Promise<Order> {
    const { data } = await this.client.post(
      `/order/v1/clients/${clientId}/orders`,
      request
    )
    return data as Order
  }

  async bulkCreateOrder(
    clientId: string,
    request: BulkCreateOrderRequest
  ): Promise<BulkCreateOrderResponse> {
    const { data } = await this.client.post(
      `/order/v1/clients/${clientId}/orders/bulk-create`,
      request
    )
    return data as BulkCreateOrderResponse
  }

  async retrieveOrder(
    clientId: string,
    orderId: string,
    type?: OrderRetrievalType
  ): Promise<Order> {
    const queryParams = new URLSearchParams()
    if (type) {
      queryParams.append("type", type)
    }
    const response = await this.client.get(
      `/order/v1/clients/${clientId}/orders/${orderId}?${queryParams}`
    )
    return response.data as Order
  }

  async retrieveOrderInternal(
    orderId: string,
    type?: OrderRetrievalType
  ): Promise<Order> {
    const queryParams = new URLSearchParams()
    if (type) {
      queryParams.append("type", type)
    }
    const response = await this.client.get(
      `/order/v1/orders/${orderId}?${queryParams}`
    )
    return response.data as Order
  }

  async cancelOrder(
    clientId: string,
    orderId: string,
    cancelReason: string
  ): Promise<Order> {
    const { data } = await this.client.post(
      `/order/v1/clients/${clientId}/orders/${orderId}/cancel`,
      { cancelReason }
    )
    return data as Order
  }

  async takeOutOrder(clientId: string, orderId: string): Promise<Order> {
    const { data } = await this.client.post(
      `/order/v1/clients/${clientId}/orders/${orderId}/take-out`
    )
    return data as Order
  }

  async patchOrder(
    clientId: string,
    invoiceNumber: string,
    payload: PatchOrderRequest
  ): Promise<Order> {
    const { data } = await this.client.patch(
      `/order/v1/clients/${clientId}/orders/${invoiceNumber}?type=invoice_number`,
      payload
    )
    return data as Order
  }

  async listRegions(
    queryParams: Record<string, string>
  ): Promise<ListRegionsResponse> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get<RawListRegionsResponse>(
      `/region/v1/regions?${paramsString}`
    )
    return {
      ...data,
      regions: data.regions.map((r) => {
        const pp = JSON.parse(
          r.polygon
            .split("POLYGON")
            .join("")
            .split("(")
            .join("[")
            .split(")")
            .join("]")
            .split(",[")
            .join(`,["`)
            .split("],")
            .join(`"],`)
            .split("[[")
            .join(`[["`)
            .split("]]")
            .join(`"]]`)
        ) as string[][]
        const p = pp
          .map((arr) =>
            arr.map(
              (s) =>
                JSON.parse(`["` + s.split(",").join(`","`) + `"]`) as string[]
            )
          )
          .map((arr) =>
            arr.map((ss) => ss.map((e) => e.split(" ").map((n) => +n)))
          )[0]
        return { ...r, polygon: p }
      }),
    }
  }

  async retrieveRegion(
    regionId: string,
    type: string = "id"
  ): Promise<RetrieveRegionResponse> {
    const { data } = await this.client.get<RetrieveRegionResponse>(
      `/region/v1/regions/${regionId}?type=${type}`
    )
    return data
  }

  async listClientRegions(
    clientId: string,
    queryParams: Record<string, string>
  ): Promise<ListRegionsResponse> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get<RawListRegionsResponse>(
      `/region/v1/clients/${clientId}/regions?${paramsString}`
    )
    return {
      ...data,
      regions: data.regions.map((r) => {
        return { ...r, polygon: null }
      }),
    }
  }

  /********************
   Billing API
   ********************/

  /** Billing */

  async createBilling(req: CreateBillingRequest): Promise<Billing> {
    const { data } = await this.client.post(`/billing/v1/billings`, req)
    return data as Billing
  }

  listBillings(req: ListBillingsRequest) {
    const params = withPagination(req)
    if (req.filter) {
      params.set(
        "filter",
        encodeFilter(req.filter, {
          readyDateFrom: ["ready_date_from", (d) => d.format("YYYY-MM-DD")],
          readyDateTo: ["ready_date_from", (d) => d.format("YYYY-MM-DD")],
          billingTypes: ["billing_type"],
        })
      )
    }
    return this.getInvoker<Billing, "billings">(
      `/billing/v1/billings?${params}`
    )
  }

  getBilling(billingId: string) {
    return this.getInvoker<Billing>(`/billing/v1/billings/${billingId}`)
  }

  async deleteBilling(billingId: string): Promise<void> {
    await this.client.delete(`/billing/v1/billings/${billingId}`)
  }

  async createBillingTaxInvoice(billingId: string): Promise<TaxInvoice> {
    const { data } = await this.client.post(
      `/billing/v1/billings/${billingId}/tax-invoice`
    )
    return data as TaxInvoice
  }

  listBillingOrders(billingId: string, req: ListBillingOrdersRequest) {
    const params = withPagination(req)
    return this.getInvoker<ListOrdersResponse>(
      `/billing/v1/billings/${billingId}/orders?${params}`
    )
  }

  async deprecatedListBillingOrders(
    billingId: string,
    params: PaginationRequest
  ): Promise<ListOrdersResponse> {
    const paramsString = withPagination(params)
    const { data } = await this.client.get(
      `/billing/v1/billings/${billingId}/orders?${paramsString}`
    )
    return data as ListOrdersResponse
  }

  /** Client Billing */

  listClientBillings(clientId: string, req: ListClientBillingsRequest) {
    const params = withPagination(req)
    if (req.filter) {
      params.set(
        "filter",
        encodeFilter(req.filter, {
          readyDateFrom: ["ready_date_from", (d) => d.format("YYYY-MM-DD")],
          readyDateTo: ["ready_date_from", (d) => d.format("YYYY-MM-DD")],
          billingTypes: ["billing_type"],
        })
      )
    }
    return this.getInvoker<Billing, "billings">(
      `/billing/v1/clients/${clientId}/billings?${params}`
    )
  }

  getClientBilling(clientId: string, billingId: string) {
    return this.getInvoker<Billing>(
      `/billing/v1/clients/${clientId}/billings/${billingId}`
    )
  }

  async createVirtualAccount(
    clientId: string,
    billingId: string
  ): Promise<Billing> {
    const { data } = await this.client.post(
      `/billing/v1/clients/${clientId}/billings/${billingId}/virtual-account`
    )
    return data as Billing
  }

  async renewVirtualAccount(
    clientId: string,
    billingId: string
  ): Promise<Billing> {
    const { data } = await this.client.post(
      `/billing/v1/clients/${clientId}/billings/${billingId}/virtual-account/renew`
    )
    return data as Billing
  }

  listClientBillingOrders(
    clientId: string,
    billingId: string,
    req: ListClientBillingOrdersRequest
  ) {
    const params = withPagination(req)
    return this.getInvoker<ListOrdersResponse>(
      `/billing/v1/clients/${clientId}/billings/${billingId}/orders?${params}`
    )
  }

  listClientOrderSummaries(req: ListClientOrderSummariesRequest) {
    const params = withPagination(req)
    if (req.filter) {
      params.set(
        "filter",
        encodeFilter(req.filter, {
          deliverDateFrom: ["deliver_date_from", (d) => d.format("YYYY-MM-DD")],
          deliverDateTo: ["deliver_date_to", (d) => d.format("YYYY-MM-DD")],
          hasShippingFee: "has_shipping_fee",
          hasBilling: "hasBilling",
        })
      )
    }
    return this.getInvoker<ClientBillingSummary, "billingSummaries">(
      `/billing/v1/clients/orders/summary?${params}`
    )
  }

  async deprecatedListUnbilledOrdersSummary(
    params: PaginationRequest<{
      filter?: {
        deliverDateFrom?: string
        deliverDateTo?: string
      }
    }>
  ): Promise<PaginationResponse<"unbilledOrders", ClientBillingSummary>> {
    const paramsString = withPagination(params)
    if (params.filter) {
      const terms: string[] = []
      const { deliverDateFrom, deliverDateTo } = params.filter
      if (deliverDateFrom) terms.push(`deliver_date_from=${deliverDateFrom}`)
      if (deliverDateTo) terms.push(`deliver_date_to=${deliverDateTo}`)
      paramsString.set("filter", terms.join(";"))
    }
    // TODO: filter 강제 설정
    const { data } = await this.client.get(
      `/billing/v1/clients/orders/summary?${paramsString}`
    )
    return data as PaginationResponse<"unbilledOrders", ClientBillingSummary>
  }

  async deprecatedListClientBillings(
    clientId: string,
    queryParams: Record<string, string>
  ): Promise<PaginationResponse<"billings", Billing>> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get<
      PaginationResponse<"billings", Billing>
    >(`/billing/v1/clients/${clientId}/billings?${paramsString}`)
    return data
  }

  /** prepaid points related apis */

  getClientPrepaidPoints(clientId: string) {
    return this.getInvoker<PrepaidPoints>(
      `/billing/v1/clients/${clientId}/prepaid-points`
    )
  }

  async createClientPrepaidPointsTransaction(
    clientId: string,
    type: Extract<
      PrepaidPointsTransactionType,
      "POINTS_CHARGE" | "POINTS_REFUND"
    >,
    amount: number,
    refundAccount?: Account
  ): Promise<PrepaidPointsTransaction> {
    const { data } = await this.client.post(
      `/billing/v1/clients/${clientId}/prepaid-points/transactions`,
      { type, amount, ...(refundAccount ? { refundAccount } : {}) }
    )
    return data as PrepaidPointsTransaction
  }

  listClientPrepaidPointsTransactions(
    clientId: string,
    req: PaginationRequest<{
      filter?: {
        type?: PrepaidPointsTransactionType
        applied?: boolean
      }
    }>
  ) {
    const params = withPagination(req)
    if (req.filter) {
      params.set(
        "filter",
        encodeFilter(req.filter, {
          type: ["type"],
          applied: ["applied"],
        })
      )
    }
    return this.getInvoker<PrepaidPointsTransaction, "transactions">(
      `/billing/v1/clients/${clientId}/prepaid-points/transactions?${params}`
    )
  }

  getClientPrepaidPointsTransaction(clientId: string, transactionId: string) {
    return this.getInvoker<PrepaidPointsTransaction>(
      `/billing/v1/clients/${clientId}/prepaid-points/transactions/${transactionId}`
    )
  }

  async deleteClientPrepaidPointsTransaction(
    clientId: string,
    transactionId: string
  ): Promise<void> {
    await this.client.delete(
      `/billing/v1/clients/${clientId}/prepaid-points/transactions/${transactionId}`
    )
  }

  listPrepaidPoints(
    params: PaginationRequest<{
      filter?: {
        pointsCondition?: "nonzero" | "negative"
      }
    }>
  ) {
    const paramsString = withPagination(params)
    if (params.filter) {
      const terms: string[] = []
      const { pointsCondition } = params.filter
      if (pointsCondition) terms.push(`points_condition=${pointsCondition}`)
      paramsString.set("filter", terms.join(";"))
    }
    return this.getInvoker<PrepaidPoints, "prepaidPoints">(
      `/billing/v1/prepaid-points?${paramsString}`
    )
  }

  async createPrepaidPointsTransaction(
    req: Pick<
      PrepaidPointsTransaction,
      "clientId" | "type" | "amount" | "note"
    > & {
      type: Extract<
        PrepaidPointsTransaction["type"],
        | "SHIPPING_FEE_ADJUSTMENT"
        | "ADMIN_ADJUSTMENT"
        | "POINTS_REFUND"
        | "POINTS_SPEND"
      >
    }
  ): Promise<PrepaidPointsTransaction> {
    const { data } = await this.client.post(
      `/billing/v1/prepaid-points/transactions`,
      req
    )
    return data as PrepaidPointsTransaction
  }

  async createClientsPrepaidPointsTransaction(
    req: Pick<
      PrepaidPointsTransaction,
      "clientId" | "type" | "amount" | "note"
    > & {
      type: Extract<
        PrepaidPointsTransaction["type"],
        | "SHIPPING_FEE_ADJUSTMENT"
        | "ADMIN_ADJUSTMENT"
        | "POINTS_REFUND"
        | "POINTS_SPEND"
      >
    }
  ): Promise<PrepaidPointsTransaction> {
    const { data } = await this.client.post(
      `/billing/v1/clients/${req.clientId}/prepaid-points/transactions`,
      req
    )
    return data as PrepaidPointsTransaction
  }

  listPrepaidPointsTransactions(
    params: PaginationRequest<{
      filter?: {
        type?: PrepaidPointsTransactionType
        applied?: boolean
      }
    }>
  ) {
    const paramsString = withPagination(params)
    if (params.filter) {
      const terms: string[] = []
      const { type, applied } = params.filter
      if (type) terms.push(`type=${type}`)
      if (applied !== undefined)
        terms.push(`applied=${applied ? "true" : "false"}`)
      paramsString.set("filter", terms.join(";"))
    }
    return this.getInvoker<PrepaidPointsTransaction, "transactions">(
      `/billing/v1/prepaid-points/transactions?${paramsString}`
    )
  }

  async bulkExpectShippingFees(
    clientId: string,
    deliveryClass: DeliveryClass,
    payloads: any[]
  ) {
    const { data } = await this.client.post(
      `/billing/v1/clients/${clientId}/shipping-fees/bulk-expect`,
      {
        deliveryClass,
        payloads,
      }
    )
    return data as { shippingFees: number[] }
  }

  async createLotteLogisticsOrder(invoiceNumber: string): Promise<Order> {
    const { data } = await this.client.post(
      `/order/v1/lotte-logistics-orders`,
      {
        invoiceNumber,
        forceCreate: true,
      }
    )
    return data as Order
  }

  async createCJOrder(invoiceNumber: string): Promise<Order> {
    const { data } = await this.client.post(`/order/v1/cj-orders`, {
      invoiceNumber,
    })
    return data as Order
  }

  async listUnregisteredLotteLogisticsOrder(date: string): Promise<string[]> {
    const { data } = await this.client.get(
      `/order/v1/lotte-logistics-orders/unregistered-invoices?date=${date}`
    )
    return data.clientShippingIds as string[]
  }
}
