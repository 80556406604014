export type StationCode = "A" | "B" | "C" | "D"

export function getStationCode(
  regionSetName: string | undefined
): StationCode | "" {
  if (regionSetName === undefined) return ""
  switch (regionSetName.split("-")[0]) {
    case "계양":
    case "김포":
      return "A"
    case "남양주":
      return "B"
    case "하남":
      return "C"
    case "안산":
      return "D"
    default:
      return ""
  }
}
